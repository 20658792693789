import { useCallback, useState, useEffect } from 'react';
import { IGameSchedule } from './model';

export const SCORES_API = process.env.REACT_APP_SCORES_API_URL;
console.log(SCORES_API);


const parseDate = (values: any[]): IGameSchedule[] => {
  return values.map(v => {
    // convert the date in json which is in UTC to PST
    let s: string = v.date.replace('Z', '-08:00');
    v.date = new Date(s);
    return v;
  });
}

function useGameSchedule(url: string = `${SCORES_API}/schedule`): [boolean, boolean, IGameSchedule[], () => void] {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setErrors] = useState(false);
  const [schedule, setSchedule] = useState<IGameSchedule[]>([] as IGameSchedule[]);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await fetch(url);
      const json = await res.json();
      // Fix Me: 
      // This assumes there is a date field in the json object that represents Date.
      // date is represented as string in json and does not automatically deserialze to Date object
      setSchedule(parseDate(json.schedule));
      setIsLoading(false);
    }
    catch (error) {
      setErrors(true);
      setIsLoading(false);
    }
  }, [url]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const refresh = async () => {
    await fetchData();
  }

  return [isLoading, hasError, schedule, refresh];
}

export default useGameSchedule;
