import './App.css';

import { ScoreForm } from './ScoreForm';
import useGameSchedule from './Schedule';

function App() {

  const [isLoading, hasErrors, schedule, refresh] = useGameSchedule();

  const onSubmit = () => {
    refresh();
  }

  return (
    <div className="App">
      <ScoreForm schedule={schedule} onSubmit={onSubmit}/>
      {isLoading && <span>Loading.....</span>}
      {!isLoading && hasErrors && (
        <>
          <span>Error in loading data ...</span>
          <br />
          <span>Reload the page to try again</span>
        </>
      )}
    </div>
  );
}

export default App;
