import { useCallback, useState } from 'react';
import { SCORES_API } from './Schedule';
import { IGameSchedule } from './model';
import Toast from './Components/toast/Toast';

import './ScoreForm.css';

function isObjectEmpty(obj: Object) {
  return Object.keys(obj).length === 0;
}

interface IScoreFormProps {
  schedule: IGameSchedule[];
  onSubmit: () => void;
}

interface IScoreFormState {
  date: Date;
  gym: string;
  start_time: string;
  away_score: number;
  home_score: number;
  away_team: string;
  home_team: string;
  notes: string;
  si: IGameSchedule;
}

export function ScoreForm(props: IScoreFormProps) {
  const initialValues: IScoreFormState = {
    date: new Date(0), gym: '', start_time: '',
    home_score: 0, away_score: 0,
    away_team: '', home_team: '',
    notes: '',
    si: {} as IGameSchedule
  };
  const [values, setValues] = useState<IScoreFormState>(initialValues);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  const set = (name: string) => {
    return (event: any) => {
      setValues(oldValues => {
        let nv = name === 'date' ? new Date(event.target.value) : event.target.value

        let newValues = { ...oldValues, [name]: nv};
        const selItems = selectedItems(newValues);
        const si = selItems.length > 0 ? selItems[0] : {} as IGameSchedule;

        if ((isObjectEmpty(oldValues.si) && !isObjectEmpty(si)) ||
            (!isObjectEmpty(oldValues.si) && !isObjectEmpty(si) && oldValues.si.id !== si.id)) {
            newValues = {
              ...newValues,
              away_team: si.away_team,
              away_score: si.away_score,
              home_team: si.home_team,
              home_score: si.home_score,
              notes: si.notes === undefined ? '' : si.notes,
              si: si
            }
        }

        return newValues;
      });
    }
  };

  const submitScoresData = async (): Promise<string> => {
    // merge the state values to the selected game schedule
    const data = {
      ...selectedItems()[0],
      away_team: values.away_team,
      away_score: values.away_score,
      home_team: values.home_team,
      home_score: values.home_score,
      notes: values.notes
    }

    const response = await fetch(`${SCORES_API}/scores`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    if (response.status !== 200) {
      throw new Error(`${response.status} ${response.statusText}`);
    }
    const msg = await response.text();
    console.log(`Response recieved: ${msg}`);

    return msg;
  }

  const onSubmit = async (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    // sanity test
    let msg = '';
    try {
      // submit
      msg = await submitScoresData();
      setShowToast(true);
      setToastMessage(msg);
      setValues(initialValues);
      // Inform the parent component that form has been submitted so that it can refresh the data
      props.onSubmit();

    }
    catch (err: any) {
      alert(`Error submitting scores: ${err.message}`);
    }
  }

  const resetToast = useCallback(() => {
    setShowToast(false);
  }, []);

  const selectedItems = (items: IScoreFormState = values) => props.schedule.filter(
    s => (s.date.toDateString() === items.date.toDateString() && s._gym === items.gym && s.start_time === items.start_time)
  );

  const dates: Set<string> = new Set<string>(props.schedule.map(s => s.date.toDateString()));
  let date_options: any[] = [];
  dates.forEach(g => {
    date_options.push((() => {
      return (<option key={g} value={g}>{g}</option>)
    })())
  });

  const gyms: Set<string> = new Set<string>(props.schedule.map(s => s._gym));
  let gym_options: any[] = [];
  gyms.forEach(g => {
    gym_options.push((() => {
      return (<option key={g} value={g}>{g}</option>)
    })())
  });

  const times: Set<string> = new Set<string>(
    props.schedule
      .filter(s => (s.date.toDateString() === values.date.toDateString() && s._gym === values.gym))
      .map(s => s.start_time)
  );
  let time_options: any[] = [];
  times.forEach(g => {
    time_options.push((() => {
      return (<option key={g} value={g}>{g}</option>)
    })())
  });

  const selectedItem = selectedItems()[0];

  return (
    <div>
      <form className='score-form' onSubmit={onSubmit}>
        <fieldset>
          <legend>Game Scores</legend>

          <div className='entry-row'>
            <label htmlFor='game_date'>Date:
              <select required name='date' value={values.date.toDateString()} onChange={set('date')} id='game_date'>
                <option value=''>Select a Date</option>
                {date_options}
              </select>
            </label>
          </div>

          <div className='entry-row'>
            <label htmlFor='game_gym'>Gym:
              <select required name='gym' value={values.gym} onChange={set('gym')} id='game_gym'>
                <option value=''>Select a Gym</option>
                {gym_options}
              </select>
            </label>
          </div>

          <div className='entry-row'>
            <label htmlFor='start_time'>Time:
              <select required name='start_time' value={values.start_time} onChange={set('start_time')} id='start_time'>
                <option value=''>Select a time</option>
                {time_options}
              </select>
            </label>
          </div>
          <br/>
          {selectedItem &&
            <>
              <hr/> <br/>
              {selectedItem.division}
              <div className='row'>
                <div className='column-left'>
                  Guest<br/><br/>
                  {/* <label htmlFor='game_away_team'>{selectedItem.away}</label> */}
                  <input 
                  name='away_team' type='string' value={values.away_team} onChange={set('away_team') }/>
                  <input required min='0'
                    name='away_score' type='number' value={values.away_score} onChange={set('away_score')} />
                </div>

                <div className='column-right'>
                  Home<br/><br/>
                  {/* <label htmlFor='game_home_team'>{selectedItem.home}</label> */}
                  <input 
                  name='home_team' type='string' value={values.home_team} onChange={set('home_team') }/>
                  <input required min='0'
                    name='home_score' type='number' value={values.home_score} onChange={set('home_score')} />
                </div>
              </div>

              <br/> <hr/> <br/>
              <div className='entry-row'>
                <label htmlFor='notes'>Notes:</label>
              <textarea id='notes' value={values.notes} onChange={set('notes')}/>
              </div>

              <button className='submit-btn' type='submit'>Submit</button> 
            </>
          }
        </fieldset>
      </form>

      { showToast &&
        <Toast message={toastMessage} showToast={showToast} resetToast={resetToast} />
      }

      {/* { props.schedule && props.schedule.length !== 0 && 
      <GameCard games={props.schedule}/>
      } */}
    </div>
  );
}

function GameCard(props: any) {
  const games: IGameSchedule[] = props.games;
  const game = games[0];
  console.log('game card: ', props.games);

  return (
    <>
      <div className='score-card'>
        <span><h3>{game.start_time}</h3></span>
        <div className='score-input'>
          <label htmlFor=''>{game.away_team}</label>
          <input name='away_score' value={game.away_score}/>
        </div>
        <div className='score-input'>
          <label htmlFor=''>{game.home_team}</label>
          <input name='home_score' value={game.home_score}/>
        </div>
      </div>
    </>
  )
}