/**
 * Adapted from: https://github.com/uzochukwueddie/react-toast
 */

import React, { useCallback, useState, useEffect } from 'react';

import './Toast.css';
import infoIcon from './info.svg';


interface IToastDescription {
  id: string;
  title: string;
  description: string;
  backgroundColor: string;
  icon?: any;
};

interface IToastProps {
  showToast: boolean;
  message: string;
  resetToast: () => void;
};

const Toast = (props: IToastProps) => {
  const [showToast, setShowToast] = useState(props.showToast);
  const { message, resetToast } = props;

  const toast: IToastDescription = {
    id: 'one',
    title: 'Info',
    description: message,
    backgroundColor: '#252525', 
    icon: infoIcon
  }

  const autoDelete = true;
  const dismissTime = 5000;
  const position = 'bottom-center';

  const deleteToast = useCallback(() => {
    setShowToast(false);
    resetToast();
  }, [resetToast])


  useEffect(() => {
    const interval = setInterval(() => {
      if (autoDelete) {
        deleteToast();
      }
    }, dismissTime);

    return () => {
      clearInterval(interval);
    }
  }, [autoDelete, dismissTime, deleteToast]);

  return (
    <>
      {showToast &&
        <div className={`notification-container ${position}`}>
          {
            <div
              className={`notification toast ${position}`}
              style={{ backgroundColor: toast.backgroundColor }}
            >
              <button onClick={() => deleteToast()}>
                X
              </button>
              <div className="notification-image">
                <img src={toast.icon} alt="" />
              </div>
              <div>
                <p className="notification-title">{toast.title}</p>
                <p className="notification-message">
                  {toast.description}
                </p>
              </div>
            </div>
          }
        </div>
      }
    </>
  );
}

export default Toast;